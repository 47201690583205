<template>
  <el-dialog
    title="选择适用企业"
    :visible.sync="dialogVisible"
    width="700px"
    :before-close="handleClose"
  >
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="">
          <el-input
            v-model="form.data.companyId"
            size="small"
            style="width: 150px"
            placeholder="请输入企业ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="form.data.companyName"
            size="small"
            style="width: 150px"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
          <el-button type="primary" size="small" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="singleTable"
        v-loading="loading"
        size="small"
        :data="tableData"
        style="width: 100%"
        highlight-current-row
        border
        @current-change="handleChange"
      >
        <!-- 单选方法,返回选中的表格行 -->
        <el-table-column align="center" label="" width="55">
          <!--  // 添加一个多选框,控制选中与否 -->
          <!-- 单选的也可以用单选框,这样效果更明显,选择之后不能取消 -->
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="companyId" label="企业ID"> </el-table-column>
        <el-table-column prop="companyName" label="企业名称"> </el-table-column>
      </el-table>
      <Pages
        :current-page="form.pageNumber"
        :total="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="ok">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { findPageList } from '@/api/card'
import Pages from '@/components/pages'

export default {
  components: { Pages },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    multipleSelection: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        data: {
          state: 1,
        },
        pageNumber: 1,
        pageSize: 10,
      },
      tableData: [],
      selectData: [],
      total: 0,
      loading: false,
      currentRow: {},
    }
  },
  watch: {
    dialogVisible: function (val) {
      if (val) {
        this.form = {
          data: { state: 1 },
          pageNumber: 1,
          pageSize: 10,
        }
        this.getTableData()
      }
    },
  },
  methods: {
    getTableData() {
      this.loading = true
      findPageList(this.form).then((res) => {
        this.loading = false
        res.data.content.forEach((item) => {
          item.checked = false
          if (
            this.multipleSelection.length > 0 &&
            item.companyId === this.multipleSelection[0].companyId
          ) {
            console.log(item.companyId)
            item.checked = true
          }
        })
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    handleChange(val) {
      console.log(val)
      if (val) {
        this.currentRow = val
        this.tableData.forEach((item) => {
          // 排他,每次选择时把其他选项都清除
          if (item.companyId !== val.companyId) {
            item.checked = false
          }
        })
      }
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
    ok() {
      this.$emit('update:multipleSelection', [this.currentRow])
      this.$emit('update:dialogVisible', false)
      this.$emit('ok')
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    onSubmit() {
      this.form.pageNumber = 1
      this.getTableData()
    },
    reset() {
      this.form.data = {}
      this.form.pageNumber = 1
      this.getTableData()
    },
  },
}
</script>

<style lang="less" scoped>
.el-form-item {
  margin-right: 10px !important;
}
</style>
