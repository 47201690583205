<template>
  <div>
    <div v-if="!showAdd">
      <page-header @search="search" />
      <div class="table">
        <div class="btn">
          <el-button v-if="powerList.find((e) => e.nodeCode == '/admin/couponTemplate/addOrUpdateCouponT')" type="primary" size="small" icon="el-icon-plus" @click="add"
            >新增</el-button
          >
        </div>
        <Table ref="table" :table-data="tableData" :total="total" :loading="loading" @detail="detail" @edit="edit" @refresh="getTableData" />
        <Pages :current-page="form.pageNumber" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
      </div>
    </div>
    <add v-else :row="row" :show-add="showAdd" :type="type" :status="status" @back="back" />
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import add from './add'
import { pageListCouponTemplate } from '@/api/card'

export default {
  components: {
    pageHeader,
    Table,
    Pages,
    add,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      isEnable: 1,
      showAdd: false,
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      row: {},
      radio: '',
      type: '',
      status: 1,
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {},
  mounted() {
    this.getTableData()
  },
  methods: {
    back() {
      this.showAdd = false
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      console.log('请求参数', this.form)
      pageListCouponTemplate(this.form)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.content
          this.total = Number(res.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = data
      this.getTableData()
    },

    edit(row) {
      console.log(row)
      this.showAdd = true
      this.type = 'edit'
      this.status = row.status
      this.row = row
    },
    detail(row) {
      this.status = row.status
      this.type = 'detail'
      this.showAdd = true
      this.row = row
    },
    add() {
      this.showAdd = true
      this.type = 'add'
      this.status = 1
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
  },
}
</script>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #999;
  height: 46px;
  background-color: #f9f9f9;
  padding-right: 20px;
  border: #e9e9e9 1px solid;
  margin-bottom: 15px;
  .text {
    padding: 0 4px;
    font-size: 14px;
  }
}
::v-deep .is-disabled {
  .el-checkbox__inner {
    cursor: pointer !important;
    background-color: #fff !important;
  }
  .is-checked .el-checkbox__inner {
    background-color: #409eff !important;
  }
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
  cursor: pointer !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}
</style>
