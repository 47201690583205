<template>
  <el-table ref="multipleTable" v-loading="loading" :data="tableData" border style="width: 100%">
    <el-table-column prop="couponTemplateId" label="卡券ID" width="70"> </el-table-column>
    <el-table-column prop="couponName" label="卡券名称" width="200">
      <template slot-scope="scope">
        <span>{{ scope.row.couponName }}</span>
        <span v-if="scope.row.isEffect == 1"> 「<span style="color: #d9001b">已过期</span>」 </span>
      </template>
    </el-table-column>

    <el-table-column prop="couponKind" label="卡券种类" width="100">
      <template slot-scope="scope">
        <span>
          {{ scope.row.couponKind == 1 ? '优惠券' : scope.row.couponKind == 2 ? '产品券' : '' }}
        </span>
      </template>
    </el-table-column>

    <el-table-column prop="couponType" label="卡券类型" width="100">
      <template slot-scope="scope">
        <span>
          {{
            scope.row.couponType == 1
              ? '立减券'
              : scope.row.couponType == 2
              ? '满减券'
              : scope.row.couponType == 3
              ? '折扣券'
              : ''
          }}
        </span>
      </template>
    </el-table-column>

    <el-table-column label="优惠折扣（折）" width="100">
      <template slot-scope="scope"> {{ Number(scope.row.couponDiscount).toFixed(1) }} </template>
    </el-table-column>

    <el-table-column prop="withAmount" label="使用门槛金额（￥）" width="120">
      <template slot-scope="scope">
        <span>
          {{ scope.row.couponType == 1 ? '--' : scope.row.withAmount }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="couponAmount" label="优惠券金额（￥）" width="100"> </el-table-column>
    <el-table-column prop="validStartTime" label="生效时间" width="160">
      <template slot-scope="scope">
        <span>
          {{ scope.row.validStartType == 1 ? '发放后立即生效' : scope.row.validStartTime }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="validEndTime" label="失效时间" width="160">
      <template slot-scope="scope">
        <span>
          {{
            scope.row.validType == 1
              ? '生效后' + scope.row.validVal + getvalidUnit(scope.row.validUnit)
              : scope.row.validType == 2
              ? scope.row.validEndTime
              : scope.row.validType == 3
              ? '永久有效'
              : ''
          }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="quota" label="总发放量" width="100"> </el-table-column>
    <el-table-column prop="takeLimit" label="单人最大领取量" width="120"> </el-table-column>
    <el-table-column prop="takeNum" label="已发放" width="100">
      <template slot-scope="scope">
        <el-link :underline="false" type="primary" @click="goRecord(scope.row)">{{
          scope.row.takeNum
        }}</el-link>
      </template>
    </el-table-column>
    <el-table-column prop="useNum" label="已使用" width="100">
      <template slot-scope="scope">
        <el-link :underline="false" type="primary" @click="goRecord(scope.row, '2')">{{
          scope.row.useNum
        }}</el-link>
      </template>
    </el-table-column>
    <el-table-column prop="productScope" label="适用类型" width="100">
      <template slot-scope="scope">
        <span>
          {{ scope.row.productScope == 2 ? '套系产品' : '基础产品' }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="companyId" label="适用范围" width="100">
      <template slot-scope="scope">
        <span>
          {{
            scope.row.companyId == 0
              ? '平台用户'
              : scope.row.companyId == -1
              ? '活动专用'
              : '企业员工'
          }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="pickupMethod" label="领取方式" width="100">
      <template slot-scope="scope">
        <span>
          {{ scope.row.pickupMethod == 2 ? '扫二维码' : '正常领取' }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="companyName" label="适用企业" width="180">
      <template slot-scope="scope">
        <span>
          {{ scope.row.companyId == 0 ? '--' : scope.row.companyName }}
        </span>
      </template>
    </el-table-column>
    ><el-table-column fixed="right" prop="status" label="审批状态" width="90">
      <template slot-scope="scope">
        <span
          :style="{
            color:
              scope.row.status == 3
                ? '#70B603'
                : scope.row.status == 1
                ? '#02A7F0'
                : scope.row.status == 2
                ? '#D9001B'
                : '',
          }"
        >
          {{
            scope.row.status == 1
              ? '待审批'
              : scope.row.status == 2
              ? '已驳回'
              : scope.row.status == 3
              ? '已通过'
              : ''
          }}
        </span>
      </template> </el-table-column
    ><el-table-column fixed="right" prop="isDisable" label="启用状态" width="90">
      <template slot-scope="scope">
        <span>
          {{ scope.row.isDisable == 0 ? '启用' : '停用' }}
        </span>
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="150">
      <template slot-scope="scope">
        <el-button
          v-if="
            scope.row.status == 3 &&
            scope.row.isEffect != 1 &&
            powerList.find((e) => e.nodeCode == 'GY-MARKET-CARD-ISENABLE')
          "
          type="text"
          size="small"
          @click="isEnable(scope.row)"
          >{{ scope.row.isDisable == 0 ? '停用' : '启用' }}</el-button
        >
        <el-button
          v-if="
            scope.row.status == 1 &&
            scope.row.isEffect != 1 &&
            powerList.find((e) => e.nodeCode == 'GY-MARKET-CARD-PASS')
          "
          type="text"
          size="small"
          @click="ok(scope.row)"
          >通过</el-button
        >
        <el-button
          v-if="
            scope.row.status == 1 &&
            scope.row.isEffect != 1 &&
            powerList.find((e) => e.nodeCode == 'GY-MARKET-CARD-REJECT')
          "
          type="text"
          size="small"
          @click="refuse(scope.row)"
          >驳回</el-button
        >

        <el-button
          v-if="scope.row.status == 3 || scope.row.status == 1"
          type="text"
          size="small"
          @click="detail(scope.row)"
          >详情</el-button
        >

        <el-button v-if="scope.row.isEffect != 1" type="text" size="small" @click="edit(scope.row)"
          >编辑</el-button
        >
        <!-- <el-button
          v-if="
            scope.row.isDisable == 1 &&
            powerList.find((e) => e.nodeCode == 'GY-MARKET-CARD-DEL')
          "
          style="color: red"
          @click="del(scope.row)"
          type="text"
          size="small"
          >删除</el-button
        > -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mixin } from '@/mixins'
import { isDisableOrDelete } from '@/api/card'
export default {
  mixins: [mixin],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  methods: {
    goRecord(row, state) {
      this.$router.push({
        path: '/cardVoucher/issuingRecord',
        query: { couponTemplateId: row.couponTemplateId, state },
      })
    },

    isEnable(row) {
      const isDisable = Number(row.isDisable)
      const tip =
        isDisable === 0
          ? '停用优惠券后，优惠券不可继续发放，但不影响已发放优惠券的使用。是否确定停用优惠券？'
          : '启用优惠券后，优惠券可继续发放。是否确定启用优惠券？'
      this.$confirm(tip, (isDisable === 0 ? '停用' : '启用') + '优惠券确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        isDisableOrDelete({
          data: {
            couponTemplateId: row.couponTemplateId,
            isDisable: isDisable === 0 ? 1 : 0,
          },
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '操作成功!',
          })
          this.$emit('refresh')
        })
      })
    },
    ok(row) {
      this.$confirm('是否确认通过该卡券？', '通过确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        isDisableOrDelete({
          data: {
            couponTemplateId: row.couponTemplateId,
            status: 3,
          },
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '通过成功!',
          })
          this.$emit('refresh')
        })
      })
    },
    refuse(row) {
      this.$confirm('是否确认驳回该卡券？', '驳回确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        isDisableOrDelete({
          data: {
            couponTemplateId: row.couponTemplateId,
            status: 2,
          },
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '驳回成功!',
          })
          this.$emit('refresh')
        })
      })
    },
    detail(row) {
      this.$emit('detail', row)
    },
    edit(row) {
      this.$emit('edit', row)
    },
    del(row) {
      this.$confirm('是否确认删除该卡券？', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        isDisableOrDelete({
          data: {
            couponTemplateId: row.couponTemplateId,
            isDelete: 1,
          },
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.$emit('refresh')
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.el-button {
  margin-left: 6px;
}
</style>
