<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="卡券种类">
        <el-select v-model="form.couponKind" size="small" style="width: 160px" placeholder="请选择">
          <el-option label="全部" value=""> </el-option>
          <el-option label="优惠券" value="1"> </el-option>
          <el-option label="产品券" value="2"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="卡券类型">
        <el-select v-model="form.couponType" size="small" style="width: 160px" placeholder="请选择">
          <el-option label="全部" value=""> </el-option>
          <el-option label="立减券" value="1"> </el-option>
          <el-option label="满减券" value="2"> </el-option>
          <el-option label="折扣券" value="3"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="优惠券金额">
        <el-input
          v-model="form.minCouponAmount"
          size="small"
          style="width: 130px"
          placeholder="请输入最小金额"
        ></el-input>
        -
        <el-input
          v-model="form.maxCouponAmount"
          size="small"
          style="width: 130px"
          placeholder="请输入最大金额"
        ></el-input>
      </el-form-item>

      <el-form-item label="启用状态">
        <el-select v-model="form.isDisable" size="small" style="width: 160px" placeholder="请选择">
          <el-option label="全部" value=""> </el-option>
          <el-option label="启用" value="0"> </el-option>
          <el-option label="停用" value="1"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-link type="primary" style="margin-right: 10px" @click="checkFold">{{
          fold ? '收起' : '展开'
        }}</el-link>
        <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </el-form-item>
      <template>
        <div
          v-show="fold"
          :class="fold ? 'fold' : 'unfold'"
          style="
            padding: 20px;
            border-radius: 4px;
            box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
          "
        >
          <el-form-item label="审批状态">
            <el-select v-model="form.status" size="small" style="width: 160px" placeholder="请选择">
              <el-option label="全部" value=""> </el-option>
              <el-option label="待审批" value="1"> </el-option>
              <el-option label="已驳回" value="2"> </el-option>
              <el-option label="已通过" value="3"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用范围">
            <el-select
              v-model="form.companyId"
              size="small"
              style="width: 160px"
              placeholder="请选择"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option label="企业员工" value="1"> </el-option>
              <el-option label="平台用户" value="0"> </el-option>
              <el-option label="活动专用" value="-1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用类型">
            <el-select
              v-model="form.productScope"
              size="small"
              style="width: 160px"
              placeholder="请选择"
            >
              <el-option label="套系产品" :value="2"> </el-option>
              <el-option label="基础产品" :value="3"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用企业">
            <el-select
              v-model="form.applyCompanyId"
              v-loadMore="loadMore"
              clearable
              size="small"
              style="width: 160px"
              placeholder="请选择"
              :loading="loading"
            >
              <el-option
                v-for="(n, i) in companyList"
                :key="i"
                :label="n.companyName"
                :value="n.companyId"
              >
              </el-option>
              <el-option label="企业员工" value="1"> </el-option>
              <el-option label="平台用户" value="0"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="卡券名称">
            <el-input
              v-model="form.couponName"
              size="small"
              style="width: 160px"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import { findPageList } from '@/api/card'
export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: {
        couponKind: '',
      },
      fold: false,
      customerCate: [],
      companyForm: {
        data: { state: 1 },
        pageNumber: 1,
        pageSize: 10,
      },
      companyList: [],
      lastPage: false,
      loading: false,
    }
  },
  mounted() {
    this.findPageList()
  },
  methods: {
    loadMore() {
      if (!this.lastPage) {
        this.companyForm.pageNumber++
        this.findPageList()
      }
    },
    findPageList() {
      this.loading = true
      findPageList(this.companyForm).then((res) => {
        this.loading = false
        this.companyList = this.companyList.concat(res.data.content)
        this.lastPage = res.data.lastPage
      })
    },
    onSubmit() {
      this.$emit('search', this.form)
    },
    reset() {
      this.form = {}
      this.$emit('search', {})
    },

    checkFold() {
      this.fold = !this.fold
    },
  },
}
</script>
<style lang="less" scoped></style>
