<template>
  <el-upload
    :disabled="disabled"
    class="my-uploader"
    :accept="accept"
    :action="action"
    :show-file-list="false"
    :on-error="upError"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <div v-loading="loading">
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <div v-else class="img_content">
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <div class="text">{{ text }}</div>
      </div>
    </div>
  </el-upload>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      action: process.env.VUE_APP_BASE_URL + 'misc-web-api/common/file/uploadImage',
      loading: false,
    }
  },
  methods: {
    upError() {
      this.loading = false
    },
    handleAvatarSuccess(res, file) {
      this.loading = false
      const imageUrl = res.data.http
      this.$emit('update:imageUrl', imageUrl)
    },
    beforeAvatarUpload(file) {
      let isLt2M = false
      if (this.size) {
        isLt2M = file.size / 1024 / 1024 < this.size
        if (!isLt2M) {
          this.$message.error(`上传图片大小不能超过 ${this.size}MB!`)
        } else {
          this.loading = true
        }
      } else {
        isLt2M = file.size / 1024 / 1024 < 1
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!')
        } else {
          this.loading = true
        }
      }
      return isLt2M
    },
  },
}
</script>

<style lang="less">
.my-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .img_content {
    color: #8c939d;
    width: 120px;
    height: 120px;
    display: flex;
    font-size: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .text {
      font-size: 13px;
    }
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
}
</style>
