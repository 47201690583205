<template>
  <el-dialog
    title="选择适用产品"
    :visible.sync="dialogVisible"
    width="900px"
    :before-close="handleClose"
  >
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="">
          <el-input
            v-model="form.data.productCode"
            size="small"
            style="width: 140px"
            placeholder="请输入产品ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="form.data.productName"
            size="small"
            style="width: 140px"
            placeholder="请输入产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
          <el-button type="primary" size="small" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        border
        size="small"
        :data="tableData"
        style="width: 100%"
        height="400px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column prop="productCode" min-width="60" label="产品ID"> </el-table-column>
        <el-table-column prop="productName" min-width="100" label="产品名称"> </el-table-column>
        <el-table-column
          prop="productCityNames"
          show-overflow-tooltip
          min-width="80"
          label="适用城市"
        >
        </el-table-column>
        <el-table-column prop="categoryName" min-width="80" label="产品分类"> </el-table-column>
      </el-table>
      <div style="padding: 10px 20px 0 0; text-align: right">
        已选产品数量：{{ selectData.length }}
      </div>
      <Pages
        :current-page="form.pageNumber"
        :total="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" @click="ok">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { productList, productCategory, queryTreeById } from '@/api/card'
import Pages from '@/components/pages'

export default {
  components: {
    Pages,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    multipleSelection: {
      type: Array,
      default: () => [],
    },
    productType: {
      type: String,
      default: '3',
    },
  },
  data() {
    return {
      form: {
        data: { productType: '', categoryId: '' },
        pageNumber: 1,
        pageSize: 10,
      },
      tableData: [],
      selectData: [],
      total: 0,
      loading: false,
      cateList1: [],
      cateList2: [],
    }
  },
  watch: {
    dialogVisible: function (val) {
      if (val) {
        this.form = {
          data: {
            productType: this.productType,
            categoryId: '',
            isOnShelf: '1',
          },
          pageNumber: 1,
          pageSize: 10,
        }
        this.selectData = JSON.parse(JSON.stringify(this.multipleSelection))
        this.selectData = this.removeDuplicate(this.selectData)
        this.getTableData()
        if (this.cateList2.length === 0) this.productCategory()
      }
    },
  },
  mounted() {},
  methods: {
    cateChange(e) {
      this.form.data.categoryId = ''
      queryTreeById({ data: { categoryId: [e] } }).then((res) => {
        this.cateList2 = res.data[0].childrens
      })
    },
    productCategory() {
      productCategory({ data: { categoryType: 3 } }).then((res) => {
        this.cateList1 = res.data
      })
    },
    getTableData() {
      this.loading = true
      productList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
        if (this.selectData.length > 0) {
          this.tableData.forEach((row) => {
            this.selectData.forEach((e) => {
              if (row.productId === e.productId) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(row)
                })
              }
            })
          })
        }
      })
    },
    array_diff(a, b) {
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].productId === b[i].productId) {
            a.splice(j, 1)
            j = j - 1
          }
        }
      }
      return a
    },
    handleSelectionChange(val) {
      this.selectData = this.array_diff(this.selectData, this.tableData)
      this.selectData = this.selectData.concat(val)
      this.selectData = this.removeDuplicate(this.selectData)
    },
    removeDuplicate(arr) {
      let len = arr.length
      for (let i = 0; i < len; i++) {
        for (let j = i + 1; j < len; j++) {
          if (arr[i].productId === arr[j].productId) {
            arr.splice(j, 1)
            len-- // 减少循环次数提高性能
            j-- // 保证j的值自加后不变
          }
        }
      }
      return arr
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
    ok() {
      this.$emit('update:multipleSelection', this.selectData)
      this.$emit('update:dialogVisible', false)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    onSubmit() {
      this.form.pageNumber = 1
      this.getTableData()
    },
    reset() {
      this.form.data = {
        productType: this.productType,
        categoryId: '',
      }
      this.form.pageNumber = 1
      this.getTableData()
    },
  },
}
</script>

<style lang="less" scoped>
.el-form-item {
  margin-right: 10px !important;
}
</style>
