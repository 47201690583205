<template>
  <div style="height: 100%">
    <div class="box">
      <div class="title">
        <span>{{ type == 'detail' ? '卡券详情' : '新增/编辑卡券' }}</span>
        <span v-if="type != 'detail'" class="right"><span style="color: red">*</span>为必填</span>
      </div>
      <div class="form_content">
        <el-form
          ref="ruleForm"
          :class="type == 'detail' ? 'norequired' : ''"
          :model="ruleForm"
          :rules="rules"
          :disabled="disabled"
          label-width="140px"
          class="demo-ruleForm"
          size="small"
        >
          <div class="left">
            <div class="from-title">
              <div class="line"></div>
              基本信息
            </div>
            <el-form-item label="卡券ID：" prop="couponTemplateId">
              <el-input
                v-model="ruleForm.couponTemplateId"
                disabled
                size="small"
                style="width: 200px"
                placeholder="系统自动生成"
              ></el-input>
            </el-form-item>

            <el-form-item label="卡券名称：" prop="couponName">
              <el-input
                v-model="ruleForm.couponName"
                size="small"
                style="width: 200px"
                maxlength="20"
                placeholder="请输入卡券名称"
              ></el-input>
            </el-form-item>

            <el-form-item label="卡券种类：" prop="couponKind">
              <el-radio-group v-model="ruleForm.couponKind" @change="couponKindChange">
                <el-radio :label="1">优惠券</el-radio>
                <el-radio :label="2">产品券</el-radio>
              </el-radio-group>
            </el-form-item>

            <template v-if="ruleForm.couponKind != 2">
              <el-form-item label="卡券类型：" prop="couponType">
                <el-radio-group v-model="ruleForm.couponType">
                  <el-radio :label="1">立减券</el-radio>
                  <el-radio :label="2">满减券</el-radio>
                  <el-radio :label="3">折扣券</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item
                label="优惠券金额："
                prop="couponAmount"
                v-if="ruleForm.couponType != 3"
              >
                <el-input
                  v-model="ruleForm.couponAmount"
                  size="small"
                  style="width: 200px"
                  maxlength="9"
                  placeholder="请输入优惠券金额"
                ></el-input>
                元
              </el-form-item>

              <el-form-item
                label="优惠券折扣："
                prop="couponDiscount"
                v-if="ruleForm.couponType == 3"
              >
                <el-input-number
                  v-model="ruleForm.couponDiscount"
                  :precision="1"
                  :step="0.1"
                  :max="10"
                  size="small"
                  style="width: 200px"
                  :min="0"
                ></el-input-number>
                折
              </el-form-item>

              <el-form-item
                v-if="ruleForm.couponType == 2 || ruleForm.couponType == 3"
                label="使用门槛金额："
                prop="withAmount"
              >
                <el-input
                  v-model="ruleForm.withAmount"
                  size="small"
                  maxlength="9"
                  style="width: 200px"
                  placeholder="请输入使用门槛金额"
                ></el-input>
                元
              </el-form-item>
            </template>

            <el-form-item label="发放类型：" prop="source">
              <el-radio-group v-model="ruleForm.source">
                <el-radio :label="1">自主领取</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="使用限制：" prop="useLimit">
              <el-radio-group v-model="ruleForm.useLimit">
                <el-radio :label="1">单次一张</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="总发放量：" prop="quota">
              <el-input
                v-model.number="ruleForm.quota"
                size="small"
                style="width: 200px"
                maxlength="9"
                placeholder="请输入总发放量"
              ></el-input>
            </el-form-item>
            <el-form-item label="单人最大领取量：" prop="takeLimit">
              <el-input
                v-model.number="ruleForm.takeLimit"
                size="small"
                maxlength="9"
                style="width: 200px"
                placeholder="请输入1人最大领取量"
              ></el-input>
            </el-form-item>
            <el-form-item label="卡券封面图：" prop="coverUrl">
              <UploadImg :image-url.sync="ruleForm.coverUrl" text="上传图片" />
            </el-form-item>
            <el-form-item label="使用说明：" prop="remark">
              <el-input
                v-model="ruleForm.remark"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 12 }"
                placeholder="请输入"
                maxlength="300"
                show-word-limit
              ></el-input>
            </el-form-item>
            <div class="from-title">
              <div class="line"></div>
              优惠券有效期
            </div>
            <el-form-item label="生效时间：" prop="validStartType">
              <el-radio-group v-model="ruleForm.validStartType" @change="validStartTypeChange">
                <el-radio :label="1">发放后立即生效</el-radio>
                <el-radio :label="2">固定时间</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="ruleForm.validStartType == 2" label="" prop="validStartTime">
              <el-date-picker
                v-model="ruleForm.validStartTime"
                type="datetime"
                style="width: 200px"
                placeholder="选择日期时间"
                :picker-options="{
                  disabledDate: (time) => {
                    return time.getTime() < Date.now() - 3600 * 1000 * 24
                  },
                  selectableRange: timeRange,
                }"
                @change="() => (ruleForm.validEndTime = '')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="失效时间：" prop="validType">
              <el-radio-group v-model="ruleForm.validType">
                <el-radio v-if="ruleForm.validStartType == 1" :label="1">生效后多久失效</el-radio>
                <el-radio v-if="ruleForm.validStartType == 2" :label="2">固定时间</el-radio>
                <el-radio :label="3">永久有效</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="ruleForm.validType == 1" label="" prop="validVal">
              <el-input
                v-model="ruleForm.validVal"
                size="small"
                style="width: 200px"
                maxlength="5"
                placeholder="请输入时间"
              ></el-input>
              <el-select
                v-model="ruleForm.validUnit"
                size="small"
                style="width: 80px; margin-left: 10px"
                placeholder="请选择"
              >
                <el-option label="小时" :value="1"> </el-option>
                <el-option label="天" :value="2"> </el-option>
                <el-option label="月" :value="3"> </el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="ruleForm.validType == 2" label="" prop="validEndTime">
              <el-date-picker
                v-model="ruleForm.validEndTime"
                style="width: 200px"
                type="datetime"
                popper-class="no-atTheMoment"
                placeholder="选择日期时间"
                :picker-options="{
                  disabledDate: (time) => {
                    return time.getTime() < deteNum - 3600 * 1000 * 24
                  },
                  selectableRange: timeRange1,
                }"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="right">
            <div class="from-title">
              <div class="line"></div>
              适用人群
            </div>
            <el-form-item label="适用范围：" prop="companyType">
              <el-radio-group v-model="ruleForm.companyType" @change="companyTypeChange">
                <el-radio :label="1">企业员工</el-radio>
                <el-radio :label="0">平台用户</el-radio>
                <el-radio :label="-1">活动专用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="ruleForm.companyType == 1" label="适用企业：" prop="businessData">
              <el-link
                v-if="type != 'detail'"
                type="primary"
                :underline="false"
                @click="dialogVisible1 = true"
                >点击选择企业</el-link
              >
            </el-form-item>
            <el-table
              v-if="ruleForm.companyType == 1"
              size="small"
              :data="ruleForm.businessData"
              style="width: 100%"
            >
              <el-table-column prop="companyId" label="企业ID"> </el-table-column>
              <el-table-column prop="companyName" label="企业名称"> </el-table-column>
              <el-table-column prop="address" width="50" label="操作">
                <template slot-scope="scope">
                  <i v-if="type != 'detail'" class="el-icon-error" @click="del(1, scope.row)"></i>
                </template>
              </el-table-column>
            </el-table>
            <div class="from-title">
              <div class="line"></div>
              适用产品
            </div>
            <el-form-item label="适用类型：" prop="productScope">
              <el-radio-group v-model="ruleForm.productScope" @change="productScopeChange">
                <el-radio :label="2">套系产品</el-radio>
                <el-radio :label="3">基础产品</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="适用范围：" prop="productScopeType">
              <el-radio-group v-model="ruleForm.productScopeType">
                <el-radio :label="1">全部产品</el-radio>
                <el-radio :label="2">部分产品</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="ruleForm.productScopeType == 2" label="适用产品：" required>
              <el-link
                v-if="!disabled || (type == 'edit' && status == 3)"
                type="primary"
                :underline="false"
                @click="dialogVisible2 = true"
                >{{ ruleForm.productScope == 2 ? '选择套系产品' : '选择基础产品' }}</el-link
              >
            </el-form-item>
            <el-table
              v-if="ruleForm.productScopeType == 2"
              size="small"
              :data="ruleForm.productData"
              style="width: 100%"
              height="300"
            >
              <el-table-column prop="productCode" label="产品ID" min-width="70"> </el-table-column>
              <el-table-column prop="productName" label="产品名称" min-width="120">
              </el-table-column>
              <el-table-column prop="parentCategoryName" label="一级分类" min-width="80">
              </el-table-column>
              <el-table-column prop="categoryName" label="二级分类" min-width="80">
              </el-table-column>
              <el-table-column min-width="50" label="操作">
                <template slot-scope="scope">
                  <i
                    v-if="!disabled || (type == 'edit' && status == 3)"
                    class="el-icon-error"
                    @click="del(2, scope.row)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>

            <div v-if="ruleForm.companyType !== -1" class="from-title">
              <div class="line"></div>
              领取方式
            </div>
            <el-form-item v-if="ruleForm.companyType === 0" label="领取方式：" prop="pickupMethod">
              <el-radio-group v-model="ruleForm.pickupMethod">
                <el-radio :label="1">正常领取</el-radio>
                <el-radio :label="2">扫二维码</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="qrcode && ruleForm.pickupMethod == 2" label="二维码：">
              <div class="qrcode-box">
                <el-image :src="qrcode" fit="fill" class="qrcode-box__img"></el-image>
                <el-button
                  v-if="ruleForm.isDisable == 0"
                  icon="el-icon-download"
                  size="mini"
                  type="primary"
                  :disabled="false"
                  @click="downloadQRCode"
                  >下载</el-button
                >
              </div>
            </el-form-item>

            <div v-if="type == 'detail'">
              <div class="from-title">
                <div class="line"></div>
                创建信息
              </div>
              <el-form-item label="创建人：">
                <el-input
                  v-model="ruleForm.createUserName"
                  disabled
                  size="small"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="创建时间：">
                <el-input
                  v-model="ruleForm.createTime"
                  disabled
                  size="small"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="footer">
          <el-button size="small" @click="cancel">{{
            type == 'detail' ? '返回' : '取消'
          }}</el-button>
          <el-button
            v-if="!disabled || (type == 'edit' && status == 3)"
            :loading="btnLoading"
            size="small"
            type="primary"
            @click="ok"
            >保存</el-button
          >
        </div>
      </div>
    </div>
    <Business
      :dialog-visible.sync="dialogVisible1"
      :multiple-selection.sync="ruleForm.businessData"
    />
    <Product
      ref="productList"
      :dialog-visible.sync="dialogVisible2"
      :product-type="productType"
      :multiple-selection.sync="ruleForm.productData"
    />
  </div>
</template>

<script>
import moment from 'moment'
import UploadImg from '@/components/uploadImg'
import Business from './popup/business'
import Product from './popup/product'
import { createQrCode, getQrCode } from '@/api/card.js'
import { addOrUpdateCouponT, detailCouponTemplate, findListConditionPage } from '@/api/card'
export default {
  components: { UploadImg, Business, Product },
  props: {
    showAdd: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
    status: {
      type: [Number, String],
      default: '',
    },
  },

  data() {
    const validateMoney = (rule, value, callback) => {
      if (/^(?!-)\d+(\.\d{1,2})?$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正数数值'))
      }
    }

    const validateNum = (rule, value, callback) => {
      if (/^[0-9]*[1-9][0-9]*$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正整数'))
      }
    }
    return {
      confirmLoading: false,
      confirmVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      timeRange: [moment().format('HH:mm:ss') + '-23:59:59'],
      timeRange1: [moment().add(1, 'm').format('HH:mm:ss') + '-23:59:59'],
      deteNum: Date.now(),
      ruleForm: {
        productScope: 2,
        productScopeType: 1,
        validUnit: 1,
        source: 1,
        useLimit: 1,
        validVal: '',
        validType: '',
        companyType: 0,
        validStartType: '',
        validStartTime: '',
        validEndTime: '',
        productData: [],
        businessData: [],
        pickupMethod: 1,
        couponKind: 1,
        couponType: 1,
        couponDiscount: 0,
        couponAmount: '',
        withAmount: '',
      },

      btnLoading: false,
      rules: {
        couponName: [{ required: true, message: '请输入卡券名称', trigger: 'blur' }],
        source: [{ required: true, message: '请选择发放类型', trigger: 'change' }],

        couponType: [{ required: true, message: '请选择卡券类型', trigger: 'change' }],
        couponAmount: [
          { required: true, message: '请输入优惠券金额', trigger: 'blur' },
          { validator: validateMoney, trigger: 'blur' },
        ],
        withAmount: [
          { required: true, message: '请输入使用门槛金额', trigger: 'blur' },
          { validator: validateMoney, trigger: 'blur' },
        ],
        useLimit: [{ required: true, message: '请选择使用限制', trigger: 'change' }],
        pickupMethod: [{ required: true, message: '请选择使用方式', trigger: 'change' }],
        quota: [
          { required: true, message: '请输入总发放量', trigger: 'blur' },
          { validator: validateNum, trigger: 'blur' },
        ],
        takeLimit: [
          {
            required: true,
            message: '请输入单人最大领取量',
            trigger: 'blur',
          },
          { validator: validateNum, trigger: 'blur' },
        ],
        coverUrl: [{ required: true, message: '请上传卡券封面图', trigger: 'change' }],
        remark: [{ required: true, message: '请输入使用说明', trigger: 'blur' }],
        validStartType: [{ required: true, message: '请选择生效时间', trigger: 'change' }],
        validStartTime: [{ required: true, message: '请选择生效时间', trigger: 'change' }],

        validType: [{ required: true, message: '请选择失效时间', trigger: 'change' }],
        validEndTime: [{ required: true, message: '请选择失效时间', trigger: 'change' }],
        validVal: [
          { required: true, message: '请输入失效时间', trigger: 'blur' },
          { validator: validateNum, trigger: 'blur' },
        ],
        companyType: [{ required: true, message: '请选择适用人群', trigger: 'change' }],
        productScope: [{ required: true, message: '请选择适用产品', trigger: 'change' }],
        productScopeType: [{ required: true, message: '请选择适用类型', trigger: 'change' }],
        businessData: [{ required: true, message: '请选择适用企业', trigger: 'change' }],
        productData: [{ required: true, message: '请选择适用产品', trigger: 'change' }],
        couponKind: [{ required: true, message: '请选择卡券种类', trigger: 'change' }],
        couponDiscount: [{ required: true, message: '请输入折扣', trigger: 'blur' }],
      },
      options: {},
      tableData: [],
      qrcode: '',
      disabled: false,
      productType: '3',
    }
  },
  watch: {
    'ruleForm.validStartTime': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && this.type !== 'detail') {
          const dt = moment().format('YYYY-MM-DD HH:mm:ss').split(' ')
          let st = ''
          if (moment(newValue).format('YYYY-MM-DD') === dt[0]) {
            st = dt[1]
          } else {
            st = '00:00:00'
          }
          this.timeRange = [st + '-23:59:59']
          this.timeRange1 = [moment(newValue).add(1, 'm').format('HH:mm:ss') + '-23:59:59']
          this.deteNum = moment(newValue).valueOf()
        }
      },
    },
    'ruleForm.validEndTime': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && this.type !== 'detail') {
          let dt = ''
          let st = ''
          if (this.ruleForm.validStartTime) {
            dt = moment(this.ruleForm.validStartTime)
              .add(1, 'm')
              .format('YYYY-MM-DD HH:mm:ss')
              .split(' ')
          } else {
            dt = moment().format('YYYY-MM-DD HH:mm:ss').split(' ')
          }
          if (moment(newValue).format('YYYY-MM-DD') === dt[0]) {
            st = dt[1]
          } else {
            st = '00:00:00'
          }
          this.timeRange1 = [st + '-23:59:59']
        }
      },
    },
  },

  mounted() {
    if (this.type === 'detail' || this.type === 'edit') {
      this.getDetail()
    }
    if ((this.type === 'edit' && this.status === 3) || this.type === 'detail') {
      this.disabled = true
    }
    this.options = {
      disabledDate: (time) => {
        console.log(time.getTime() < Date.now() - 3600 * 1000 * 24)
        return time.getTime() < Date.now() - 3600 * 1000 * 24
      },
    }
  },
  methods: {
    couponKindChange() {
      this.ruleForm.couponDiscount = 0
      this.ruleForm.couponAmount = ''
      this.ruleForm.withAmount = ''
    },
    productScopeChange(e) {
      this.productType = e === 2 ? '3' : '1'
      this.ruleForm.productData = []
      this.$refs.productList.reset()
    },
    companyTypeChange(e) {
      console.log(e)
      if (e === -1 || e === 1) {
        this.ruleForm.pickupMethod = 1
      }
    },
    getTableData() {
      findListConditionPage({
        data: {
          couponTemplateId: this.row.couponTemplateId,
        },
        pageNumber: 1,
        pageSize: 999,
      }).then((res) => {
        this.$set(this.ruleForm, 'productData', res.data.content)
        this.$forceUpdate()
      })
    },
    getDetail() {
      detailCouponTemplate({
        data: this.row.couponTemplateId,
      }).then((res) => {
        this.ruleForm = { ...res.data }
        if (this.ruleForm.companyId === -1) {
          this.$set(this.ruleForm, 'companyType', -1)
        } else {
          this.$set(this.ruleForm, 'companyType', this.ruleForm.companyId === 0 ? 0 : 1)
        }

        if (this.ruleForm.companyId !== 0 && this.ruleForm.companyId !== -1) {
          this.$set(this.ruleForm, 'businessData', [
            {
              companyId: this.ruleForm.companyId,
              companyName: this.ruleForm.companyName,
            },
          ])
        }
        if (this.ruleForm.productScopeType === 2) {
          this.getTableData()
        }
        this.ruleForm.pickupMethod === 2 && this.getQrCode(this.ruleForm.couponTemplateId)
      })
    },
    validStartTypeChange(e) {
      this.ruleForm.validType = ''
      this.ruleForm.validUnit = 1
      this.ruleForm.validVal = ''
      this.ruleForm.validStartTime = ''
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = { ...this.ruleForm }
          if (data.productScopeType === 2 && !data.productData.length) {
            return this.$message('请选择适用产品~')
          }
          if (data.companyType === 0) {
            data.companyId = 0
          }
          if (data.companyType === -1) {
            data.companyId = -1
          }
          if (data.validEndTime) {
            data.validEndTime = moment(data.validEndTime).format('YYYY-MM-DD HH:mm:ss')
          }
          if (data.validStartTime) {
            data.validStartTime = moment(data.validStartTime).format('YYYY-MM-DD HH:mm:ss')
          }

          if (data.productScopeType === 2) {
            data.productIds = this.ruleForm.productData.map((e) => e.productId)
          }
          if (data.companyType === 1 && this.ruleForm.businessData.length > 0) {
            data.companyId = this.ruleForm.businessData[0].companyId
          }

          this.btnLoading = true
          console.log(data)
          addOrUpdateCouponT({ data })
            .then((res) => {
              this.btnLoading = false
              this.$message({
                message: '操作成功',
                type: 'success',
              })
              this.ruleForm.pickupMethod === 2 && this.createQrCode(res.data)
              this.$emit('back')
            })
            .catch(() => {
              this.btnLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    createQrCode(shareSourceId) {
      createQrCode({
        data: {
          shareType: 100,
          shareSourceId: 100 + Number(shareSourceId),
        },
      }).then((res) => {
        console.log(res)
      })
    },
    getQrCode(shareSourceId) {
      getQrCode({
        shareType: 100,
        shareSourceId: 100 + Number(shareSourceId),
      }).then((res) => {
        console.log(res)
        this.qrcode = res.data.miniPromotionCode
      })
    },
    downloadQRCode() {
      const $a = document.createElement('a')
      $a.setAttribute('href', this.qrcode)
      $a.setAttribute('download', '')

      $a.click()
    },

    cancel() {
      this.$emit('back')
    },
    del(e, row) {
      if (e === 1) {
        for (let i = 0; i < this.ruleForm.businessData.length; i++) {
          if (this.ruleForm.businessData[i].companyId === row.companyId) {
            this.ruleForm.businessData.splice(i, 1)
          }
        }
      }
      if (e === 2) {
        for (let i = 0; i < this.ruleForm.productData.length; i++) {
          if (this.ruleForm.productData[i].productId === row.productId) {
            this.ruleForm.productData.splice(i, 1)
          }
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  border: 1px solid #eee;
  background-color: #fff;
}
.norequired {
  ::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    content: '' !important;
  }
}

.form_content {
  border: none;
  padding: 20px 0;
  .demo-ruleForm {
    display: flex;
    justify-content: space-between;
    .el-icon-error {
      font-size: 18px;
      cursor: pointer;
    }
    .left,
    .right {
      flex: 1;
      padding: 0 20px;
      .from-title {
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 16px 0;
        .line {
          width: 4px;
          height: 20px;
          background-color: #0079fe;
          margin-right: 8px;
        }
      }
    }
    .left {
      border-right: 1px #999 solid;
    }
  }
  .footer {
    padding-top: 20px;
    text-align: center;
  }
}
.title {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  .right {
    font-weight: normal;
    font-size: 14px;
    color: #999;
  }
}
.qrcode-box {
  display: flex;
  align-items: center;
  &__img {
    margin-right: 24px;
    width: 100px;
    height: 100px;
  }
}
</style>
